/* JobApplicationForm.css */
.formContainer {
    max-width: 540px;
    margin: 40px auto;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0 2px 10px rgba(0,0,0,0.15);
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
  }
  
  .formHeading {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .formLabel {
    margin-bottom: 8px;
    color: #666;
    font-weight: 500;
  }
  
  .formInput,
  .formSelect,
  .formTextarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }
  
  .formInput:focus,
  .formSelect:focus,
  .formTextarea:focus {
    border-color: #0056b3;
    box-shadow: 0 0 0 2px rgba(0,86,179,0.2);
    outline: none;
  }
  
  .formButton {
    display: block;
    width: 100%;
    padding: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .formButton:hover {
    background-color: #0056b3;
  }
  
  .fileUploadInput {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }
  
  .fileUploadLabel {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 12px 30px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .fileUploadLabel:hover {
    background-color: #0056b3;
  }
  